.lolpros-body:has(#fake-table) {
    padding: 1rem;
}

#filter-lane, #filter-region {
    display: block;
    height: 2rem;
    margin: 5px 0;
    width: -webkit-fill-available;
}

#fake-table {
    color: #fff;
    display: flex;
    flex-flow: column;
}

#fake-table .fake-table-row {
    background-color: rgb(36 38 43);
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    margin: 15px 0;
    padding: 1.5rem;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 50%;
}

#fake-table .fake-table-top {
    display: flex;
}

#fake-table .fake-table-ranking {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
}

#fake-table .fake-table-role {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 25%;
}

#fake-table .fake-table-player {
    display: flex;
    align-items: center;
    width: 35%;
}

#fake-table .fake-table-socials {
    display: flex;
}

#fake-table .div-player-social.live::before {
    top: calc(50% - 23px);
}

#fake-table .fake-table-socials svg {
    height: 30px;
}

#fake-table .fake-table-ranks {
    display: flex;
    flex-flow: column;
    margin: 15px 0;
}

#fake-table .div-rank {
    justify-content: space-around;
}

#fake-table .div-rank img {
    width: 25%;
}

#fake-table .div-rank span {
    padding: 0;
    width: 50%;
}

#fake-table span.tooltip-team-participant-name.t100 {
    margin-left: 5px;
}

#fake-table span.tooltip-team-participant-name.t200 {
    margin-right: 5px;
}

#fake-table .player-data-top {
    display: flex;
    flex-flow: column;
    margin-top: 10px;
}

#fake-table .champion-data div {
    font-size: 0.7rem;
}

#fake-table .match {
    flex-flow: column;
    font-size: 0.65rem;
}

.mobile-match-top .match {
    flex-flow: row;
}

.mobile-match-top .match-general { 
    margin: 0;
    flex-flow: row;
}

.mobile-match-bottom {
    display: flex;
    margin-top: 10px;
}

.mobile-match-bottom .match-player-stats {
    display: flex;
    margin-right: 2vw;
}

.mobile-match-bottom .match-player-champion-icon {
    max-width: 38px;
    max-height: 38px;
}

.mobile-match-bottom .match-player-overall {
    font-size: .65rem;
    margin-right: 2vw;
    min-width: auto;
}

.mobile-match-bottom .player-items ul {
    flex-flow: wrap;
    max-width: 100px;
}

#fake-table .next-button {
    opacity: .4;
}

#fake-table .match.victory {
    border-left: 3px solid #2EA9E6;
}

#fake-table .match.defeat {
    border-left: 3px solid #E62E2E;
}