.multistream[data-v-92ff8c24] {
    max-width: 100%;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    overflow-y: hidden;
    position: relative;
}
.screen-lock[data-v-92ff8c24] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
}
.multistream .player[data-v-92ff8c24] {
    flex: 0 0 33.3333%;
    border: 0;
}
.multistream .player.selected[data-v-92ff8c24] {
    flex: 1 1;
}
.multistream .mini[data-v-92ff8c24]:not(.selected) {
    position: absolute;
    bottom: 0px;
    display: block !important;
    background-color: black;
    border: 1px solid white !important;
    z-index: 1;
}
.player.mini.animate[data-v-92ff8c24]:not(.selected) {
    transition: 0.8s ease;
}
.player.mini.in[data-v-92ff8c24]:not(.selected) {
    width: 31.5% !important;
    height: 31.5% !important;
}
.player.mini.full[data-v-92ff8c24]:not(.selected) {
    width: 100% !important;
    height: 100% !important;
}
.player.mini.out[data-v-92ff8c24]:not(.selected) {
    width: 0% !important;
    height: 0% !important;
}
#iframe-overlay[data-v-92ff8c24] {
    pointer-events: none;
    position: absolute;
    top: 0;
}
@media screen and (max-width: 820px) {
.multistream .player[data-v-92ff8c24] {
        flex: 0 0 100%;
        overflow-y: auto;
}
}