.OficialGamesWidget::-webkit-scrollbar, .OficialGamesWidget::-webkit-scrollbar-track {
    background: #090909;
}

.OficialGamesWidget::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.OficialGamesWidget {
    display: flex;
    position: relative;
    height: 140px;
    background: #090909;
    border: 1px solid #252c32;
    -webkit-animation: fadein .8s;
            animation: fadein .8s;
    overflow-y: hidden;
    max-height: 140px;
    transition: max-height .4s ease-in-out;
    margin-bottom: 20px;
}

.OficialMatchWidget {
    position: relative;
    height: 100%;
}

.OficialGamesWidget .event-list {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    height: 100%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    transition-property: left;
    transition-duration: .7s;
    transition-timing-function: ease;
    border-right: 1px solid #252c32;
}

.OficialMatchWidget .OficialMatch {
    height: 100%;
}

.OficialMatchWidget .OficialMatch.future {
    padding: 40px 0 0 34px;
    width: 150px;
}

.OficialMatchWidget.day-section-start:not(:first-child):after {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #252c32;
}

.OficialMatchWidget .OficialMatch.future .date {
    position: absolute;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    color: #8fa3b0;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.OficialMatchWidget .OficialMatch.future .teams {
    padding-top: 4px;
}

.OficialMatchWidget .OficialMatch.future .teams .team {
    padding-top: 2px;
}

.OficialMatchWidget .OficialMatch.future .teams .team img {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
    vertical-align: middle;
}

.OficialMatchWidget .OficialMatch.future .teams .team .code {
    font-size: 0.95rem;
    margin: 9px 8px 0 8px;
}

.OficialMatchWidget .OficialMatch.future .time {
    font-size: 0.8rem;
}