* {
    padding: 0;
    margin: 0;
}

a {
    color: #fff;
    text-decoration: none;
}

/* width */
::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background: #111111; 
    margin: 0 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #111111; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    -webkit-border-radius: 1ex;
    border: 3px solid #111111;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

progress.blue, progress.red {
    height: 10px;
    border: 0px;
}

progress::-webkit-progress-bar {
    background-color: #d5d5d5;
    border-radius: 5px;
 }

progress::-webkit-progress-value {
    border-radius: 5px 0 0 5px;
}

progress.red::-webkit-progress-value {
    background-color: #E62E2E;
}

progress.blue::-webkit-progress-value {
    background-color: #2EA9E6;
}

.lolpros-body {
    margin: 0 auto;
    max-width: 1280px;
    padding: 1.5rem;
}

.span-playing-now {
    width: 100%;
    display: block;
    text-align: right;
    color: white;
    margin-bottom: 15px;
}

#searchBar {
    background-image: url('https://victti-dev.com.br/public/images/searchicon.png');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    width: -webkit-fill-available;
}

.button-filter-lane {
    background-color: #272727;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 2px;
    padding: 10px 20px;
    text-align: center;
}

.button-filter-lane:hover {
    background-color: #3f3f3f;
}

.button-filter-lane.selected {
    background-color: #f1f1f1;
    color: black;
}

.filter-lane-divider {
    padding: 10px 1px;
    background-color: #CCC;
    margin: 0px 5px;
}

table {
    border-spacing: 0 1rem;
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
}

tr {
    height: 3.5rem;
    margin: 1rem;
}

tr.player-row {
    background-color: rgb(36 38 43);
    border-radius: 10%;
}

th {
    color: #ffffffe6;
}

td {
    color: #fff;
    padding: 10px;
}

tr td:first-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px; }

tr td:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px; }

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.img-role {
    margin: 0.5rem;
    width: 50%;
    -webkit-filter: drop-shadow(3px 3px #000);
    filter: drop-shadow(3px 3px #00000099);
}

.text-shadow {
    text-shadow: 3px 3px 2px #00000099;
}

.div-player {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.div-player img {
    margin: 0 10px 0 0;
}

.div-player-top {
    margin-bottom: 0.25rem;
}

.semi-bold {
    font-size: 1.2rem;
    font-weight: 600;
}

.bold {
    font-weight: bold;
}

.div-player-bottom {
    display: flex;
    flex-direction: column;
}

.td-role { 
    padding: 40px 0px;
}

.div-rank {
    align-items: center;
    display: flex;
    white-space: nowrap;
    position: relative;
}

.div-rank span {
    padding: 1.5rem;
    width: 100%;
}

.account-tagLine {
    padding: 5px !important;
    color: #9E9EB1;
}

.ingame .account-tagLine {
    color: #fff;
}

.ingame {
    -webkit-animation: ingameGlow 1.2s ease-in-out infinite alternate;
            animation: ingameGlow 1.2s ease-in-out infinite alternate;
    font-weight: bold;
}

.ingame-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-rank span.tooltip {
    position: absolute;
    text-align: center;
    visibility: hidden;
    background-color: #000;
    color: #FFF;
    padding: 5px 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 350px;
}

.div-rank span.tooltip:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}

.div-rank:hover span.tooltip {
    visibility: visible;
    opacity: 0.9;
    top: 75px;
    z-index: 999;
}

.tooltip-teams {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
}

.tooltip-team {
    width: 50%;
    display: flex;
    flex-flow: column;
}

.tooltip-team-participant {
    display: flex;
}

.tooltip-team-participant img {
    width: 25%;
}

span.tooltip-team-participant-name {
    display: flex;
    font-size: 60%;
    padding: 5px;
    align-items: center;
}

span.tooltip-team-participant-name.t100 {
    justify-content: flex-start;
    text-align: left;
}

span.tooltip-team-participant-name.t200 {
    justify-content: flex-end;
    text-align: right;
}

.div-winrate-top {
    display: flex;
    justify-content: space-between;
}

progress {
    width: 100%;
}

.div-player-socials {
    display: flex;
}

.div-player-social {
    align-items: center;
    display: flex;
    padding: 0 0.3rem;
    position: relative;
}

.div-player-social.live::before { 
    content: "";
    max-width: 0.8rem;
    min-height: 13px;
    width: 100%;
    position: absolute;
    background: red;
    border-radius: 100%;
    top: 0;
    right: 0;
    -webkit-animation: opacityAnim 1.2s infinite alternate;
            animation: opacityAnim 1.2s infinite alternate;
}

@-webkit-keyframes opacityAnim {
    from { opacity: 0.9; }
    to { opacity: 0.3; }
}

@keyframes opacityAnim {
    from { opacity: 0.9; }
    to { opacity: 0.3; }
}

@-webkit-keyframes ingameGlow {
    from {
        text-shadow: 0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px #fff;
    }
    
    to {
      text-shadow: 0 0 1px black, 0 0 2px black, 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #fff;
    }
}

@keyframes ingameGlow {
    from {
        text-shadow: 0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px #fff;
    }
    
    to {
      text-shadow: 0 0 1px black, 0 0 2px black, 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #fff;
    }
}

.svg-twitter {
    max-width: 1.7rem;
    min-height: 31px;
    width: 100%;
}

.svg-opgg {
    max-width: 3rem;
    min-height: 31px;
    width: 100%;
}

.svg-twitch {
    max-width: 1.7rem;
    min-height: 31px;
    width: 100%;
}

.svg-leaguepedia {
    max-width: 1.7rem;
    min-height: 31px;
    width: 100%;
}

.div-footer {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
}

.div-footer-top {
    cursor: default;
    padding: 0.5rem;
}

#backToTopButton {
    position: fixed;
    bottom: 20px;
    right: 30px;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: rgb(16 18 24);
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

#backToTopButton:hover {
    background-color: #333;
}

.flex-right {
    display: flex;
    justify-content: right;
}

.flex-left {
    display: flex;
    justify-content: left;
}

.player-row-data {
    background-color: #000000AA;
    position: relative;
    top: -16px;
}

.div-player-data-container {
    display: flex;
    flex-flow: column;
}

.player-data-top {
    display: flex;
    flex-flow: row;
}

.most-played {
    display: flex;
    flex: 1 1;
    flex-flow: column;
}

.best-winrate {
    display: flex;
    flex: 1 1;
    flex-flow: column;
}

.data-header {
    text-align: center;
    font-weight: bold;
}

.champions-data {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.champion-data {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin: 0 10px;
    max-width: 12%;
}

.champion-data img {
    border-radius: 50%;
    width: 80%;
}

.champion-data div {
    font-size: 0.9rem;
    margin-top: 5px;
    text-align: center;
}

.match-history {
    display: flex;
    flex-flow: column;
    position: relative;
}

.match {
    border-radius: 5px;
    display: flex;
    margin: 4px;
    padding: 10px;
}

.match.victory {
    background-color: #2EA9E644;
    border-left: 6px solid #2EA9E6;
}

.match.defeat {
    background-color: #E62E2E44;
    border-left: 6px solid #E62E2E;
}

.match-general {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 30px;
}

.match-player-stats {
    margin-right: 30px;
}

.match-player-stats-top {
    display: flex;
    margin-bottom: 20px;
}

.match-player-champion {
    display: flex;
}

.match-player-champion-spells {
    margin-left: 5px;
}

.spell {
    max-width: 22px;
}

.spell img {
    width: 100%;
}

.match-player-champion-kda {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.match-player-stats-bottom {
    margin-top: 20px;
}

.match-player-champion-icon {
    max-width: 48px;
    max-height: 48px;
}

.match-player-champion-icon img {
    border-radius: 50%;
    width: 100%;
}

.player-items ul {
    display: flex;
}

.player-items ul li {
    display: inline;
    margin-left: 2px;
    border-radius: 4px
}

.match.victory .player-items ul li {
    background-color: #2f5a6e;
}

.match.defeat .player-items ul li {
    background-color: #6e2f33;
}

.player-items ul li div {
    max-width: 22px;
    max-height: 22px;
    min-width: 22px;
    min-height: 22px;
}

.player-items ul li img {
    max-width: 100%;
    border-radius: 4px;
}

.match-player-overall {
    font-size: .85rem;
    margin-right: 30px;
    min-width: 165px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.match-player-overall-bottom {
    display: flex;
}

.match-player-overall-bottom .copy-teams {
    margin: 0 5px;
}

.replay-file, span.spectator-file, .copy-teams {
    background-color: #2AA3CC;
    border-radius: 2px;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.copy-teams {
    border: none;
    padding: 5px 10px;
    font-size: 15px;
    color: #2A2A2A;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    color: #fff;
}

.copyToClipboard-icon {
    width: 17px;
    height: 17px;
    display: block;
}

.copyToClipboard-icon.spec {
    background: url('https://victti-dev.com.br/public/images/icons/copy-white.svg');
    background-size: 100%;
}

.copyToClipboard-icon.replay {
    background: url('https://victti-dev.com.br/public/images/icons/copy-black.svg');
    background-size: 100%;
}

span.spectator-file { 
    padding: 0;
    margin: 0 5px;
}

span.spectator-file a { 
    color: #fff;
}

.replay-file a, .spectator-file a {
    padding: 5px 10px;
    display: block;
    font-size: 15px;
    color: #2A2A2A;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
}

.match-players {
    display: flex;
}

.match-players-blue, .match-players-red {
    min-width: 250px;
}

.match-players-blue ul li, .match-players-red ul li {
    display: inline;
}

.match-players-blue ul li:first-child div, .match-players-red ul li:first-child div {
    margin-top: 0px;
}

.match-players-blue ul li:last-child div, .match-players-red ul li:last-child div {
    margin-bottom: 0px;
}

.match-players-blue ul li div, .match-players-red ul li div {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin: 3px;
    background-size: 12%;
}

.match-players-blue ul li div img, .match-players-red ul li div img {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.loading-icon {
    max-width: 150px;
    margin: 0 auto;
    display: block;
}

.next-button {
    padding: 8px 16px;
    background-color: #f1f1f1;
    color: black;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
}

.next-button.button-left {
    left: 0;
    top: 45%;
}

.next-button.button-right {
    right: 0;
    top: 45%;
}

.next-button:hover {
    background-color: #ddd;
    color: black;
}