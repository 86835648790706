.div-content.bet[data-v-963223af] {
    background: rgb(0,0,8);
    background: linear-gradient(165deg, rgba(0,0,8,1) 0%, rgba(7,4,50,1) 19%, rgba(59,66,240,1) 100%);
    height: auto !important;
    min-height: calc(100vh - 5.3rem);
}
.div-content.bet .div-content-2[data-v-963223af] {
    background: linear-gradient(165deg, rgba(0,0,8,1) 0%, rgba(7,4,50,1) 19%, rgba(59,66,240,0) 100%), linear-gradient(rgb(44,50,213) 1px, transparent 1px), linear-gradient(to right, rgb(44,50,213) 1px, rgb(229, 229, 247, 0) 1px);
    background-size: 100%, 20px 20px, 20px 20px;
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 5.3rem);
    align-items: center;
    display: flex;
    flex-flow: column;
    /* justify-content: center; */
    padding: 0;
}
.bet .wrap-table[data-v-963223af] {
    margin-top: 1%;
    width: 97%;
}
.wrap-table[data-v-963223af] {
    width: 760px;
    overflow: hidden;
    padding: 0 !important;
}
.table[data-v-963223af] {
    width: 100%;
    display: table;
    margin: 0;
    padding: 0 !important;
    margin: 1% 0;
    border-radius: 10px;
    overflow:hidden;
}
.table[data-v-963223af], .row[data-v-963223af] {
    width: 100%!important;
}
.row[data-v-963223af] {
    display: table-row;
    background: #222;
}
.row.header[data-v-963223af] {
    color: #fff;
    background: #333;
}
.cell[data-v-963223af] {
    display: table-cell;
}
.row .cell[data-v-963223af] {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    font-weight: inherit!important;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #02020255;
    text-align: center;
}
.row.header .cell[data-v-963223af] {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: inherit!important;
    padding-top: 19px;
    padding-bottom: 19px;
}
.row .cell[data-v-963223af]:nth-child(1) {
    width: 50%;
    padding-left: 40px;
}
.row .cell[data-v-963223af]:nth-child(2) {
    width: 50%;
}
.bet .row .cell[data-v-963223af]:nth-child(1) {
    width: auto;
    padding: 0 20px;
}
.bet .row .cell[data-v-963223af]:nth-child(2) {
    width: auto;
}
.bet .row.header[data-v-963223af] {
    background-color: #5539c7;
}
.bet .row.header[data-v-963223af]:hover
{
    background-color: #5539c7;
}
.bet .row[data-v-963223af] {
    background-color: #241067;
}
.bet .row .cell.selected[data-v-963223af] {
    background-color: #FFFFFF10;
}
.bet .row[data-v-963223af]:hover {
    background-color: #4a32ae;
}
.bet .row.header .cell[data-v-963223af] {
    border: none;
}
.bet .row .cell[data-v-963223af] {
    border-left: 1px solid #02020255;
}
.bet .row .cell[data-v-963223af]:nth-child(1)
{
    border-left: none;
}
.filters[data-v-963223af] {
    display: flex;
    flex-flow: row;
    align-items: center;
}
.filters select[data-v-963223af] {
    color: #fff;
    width: 7%;
    background-color: #5539c7;
    border: none;
    border-radius: 6px;
    height: 15px;
    transition: .3s;
}
.filters select[data-v-963223af]:hover {
    background-color: #4a32ae;
}
.filters option[data-v-963223af]:disabled {
    color: red;
}
.filter[data-v-963223af] {
    background-color: #5539c7;
    padding: 5px 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 6px;
    margin-right: 7px;
    cursor: pointer;
    transition: .3s;
}
.filter[data-v-963223af]::after {
    content: "✖";
    margin-left: 10px;
    transition: 0.2s;
    opacity: 0;
}
.filter[data-v-963223af]:hover::after {
    opacity: 1;
}
.filter[data-v-963223af]:hover {
    background-color: #4a32ae;
}
@media screen and (max-width: 768px) {
.wrap-table[data-v-963223af] {
        overflow: auto;
}
.row.header .cell[data-v-963223af] {
        padding: 0 19px;
        padding-top: 19px;
}
.filter[data-v-963223af]::after {
        opacity: 1;
}
}