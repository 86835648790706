html {
  scroll-behavior: smooth;
}

body {
  color: #fff;
}

::selection {
  background: #e6ae49;
  color: #fff;
}

.plyr {
  width: 100%;
  height: 100%;
  min-width: 0px !important;
}