:root {
    --teamOpacity: .65;
    --teamR1: 36;
    --teamG1: 38;
    --teamB1: 43;
    --teamR2: 36;
    --teamG2: 38;
    --teamB2: 43;
}

.bg-FLUXO {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/FLUXO.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/FLUXO.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 45%;
    background-size: 25%;
}

.tooltip-team-participant.bg-FLUXO, .bg-FLUXO:hover {
    background-color: #5823a3;
}

.bg-FURIA {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/FURIA.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/FURIA.png') no-repeat;
    background-position-y: 20%;
    background-size: 27%;
}

.tooltip-team-participant.bg-FURIA, .bg-FURIA:hover {
    background-color: #000;
}

.bg-LOUD {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/LOUD.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/LOUD.png') no-repeat;
    background-position-y: 40%;
    background-size: 27%;
}

.tooltip-team-participant.bg-LOUD, .bg-LOUD:hover {
    background-color: #32a323;
}

.bg-RED {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/RED.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/RED.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 15%;
    background-size: 26%;
}

.tooltip-team-participant.bg-RED, .bg-RED:hover {
    background-color: #a32323;
}

.bg-INTZ {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/INTZ.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/INTZ.png') no-repeat;
    background-position-y: 40%;
    background-size: 27%;
}

.tooltip-team-participant.bg-INTZ, .bg-INTZ:hover {
    background-color: #bc153f;
}

.bg-RISE {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/RISE.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/RISE.png') no-repeat;
    background-position-x: 2%;
    background-position-y: 30%;
    background-size: 23%;
}

.tooltip-team-participant.bg-RISE, .bg-RISE:hover {
    background-color: #000a3d;
}

.bg-PAIN {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/PAIN.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/PAIN.png') no-repeat;
    background-position-x: -8%;
    background-position-y: 50%;
    background-size: 35%;
}

.tooltip-team-participant.bg-PAIN, .bg-PAIN:hover {
    background-color: #000;
}

.bg-RAIZEN {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/RAIZEN.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/RAIZEN.png') no-repeat;
    background-position-x: -5%;
    background-position-y: 30%;
    background-size: 27%;
}

.tooltip-team-participant.bg-RAIZEN, .bg-RAIZEN:hover {
    background-color: #a36c23;
}

.bg-LIBERTY {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/LIBERTY.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/LIBERTY.png') no-repeat;
    background-position-x: -5%;
    background-position-y: 50%;
    background-size: 30%;
}

.tooltip-team-participant.bg-LIBERTY, .bg-LIBERTY:hover {
    background-color: #237da3;
}

.bg-KEYD {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/KEYD.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/KEYD.png') no-repeat;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-KEYD, .bg-KEYD:hover {
    background-color: #3223a3;
}

.bg-KABUM {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/KABUM.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/KABUM.png') no-repeat;
    background-position-x: -3%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-KABUM, .bg-KABUM:hover {
    background-color: #a34e23;
}

.bg-LOS {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/LOS.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/LOS.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-LOS, .bg-LOS:hover {
    background-color: #0a0a0a;
}

.bg-IDL {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/IDL.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/IDL.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-IDL, .bg-IDL:hover {
    background-color: #6323a3;
}

.bg-IDL td:nth-child(-n+3), .bg-RED td:nth-child(-n+3), .bg-INTZ td:nth-child(-n+3) {
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}

.bg-FreeAgent {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/FreeAgent.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/FreeAgent.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-FreeAgent, .bg-FreeAgent:hover {
    background-color: #0a0a0a;
}

.bg-OUTROS, .bg-OTP {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/OTP.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/OTP.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-OUTROS, .tooltip-team-participant.bg-OTP, .bg-OUTROS:hover, .bg-OTP:hover {
    background-color: #0a0a0a;
}

.bg-STREAMER {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/STREAMER.svg') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/STREAMER.svg') no-repeat;
    background-position-x: 2%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-STREAMER, .bg-STREAMER:hover {
    background-color: #0a0a0a;
}

.bg-ISURUS {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/ISURUS.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/ISURUS.png') no-repeat;
    background-position-x: -8%;
    background-position-y: 50%;
    background-size: 30%;
}

.tooltip-team-participant.bg-ISURUS, .bg-ISURUS:hover {
    background-color: #2361a3;
}

.bg-LIQUID {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/LIQUID.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/LIQUID.png') no-repeat;
    background-position-x: -2%;
    background-position-y: 50%;
    background-size: 30%;
}

.tooltip-team-participant.bg-LIQUID, .bg-LIQUID:hover {
    background-color: #234ea3;
}

.bg-C9 {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/C9.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/C9.png') no-repeat;
    background-position-x: -3%;
    background-position-y: 50%;
    background-size: 30%;
}

.tooltip-team-participant.bg-C9, .bg-C9:hover {
    background-color: #2361a3;
}

.bg-T1 {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/T1.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/T1.png') no-repeat;
    background-position-x: -5%;
    background-position-y: 50%;
    background-size: 30%;
}

.tooltip-team-participant.bg-T1, .bg-T1:hover {
    background-color: #6e1919;
}

.bg-GENG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/GENG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/GENG.png') no-repeat;
    background-position-x: 2%;
    background-position-y: 35%;
    background-size: 20%;
}

.tooltip-team-participant.bg-GENG, .bg-GENG:hover {
    background-color: #a35d23;
}

.bg-DK {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/DK.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/DK.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 47%;
    background-size: 25%;
}

.tooltip-team-participant.bg-DK, .bg-DK:hover {
    background-color: #0a0a0a;
}

.bg-PSG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/PSG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/PSG.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 40%;
    background-size: 20%;
}

.tooltip-team-participant.bg-PSG, .bg-PSG:hover {
    background-color: #2523a3;
}

.bg-CFO {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/CFO.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/CFO.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 40%;
    background-size: 20%;
}

.tooltip-team-participant.bg-CFO, .bg-CFO:hover {
    background-color: #a38e23;
}

.bg-BLG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/BLG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/BLG.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-BLG, .bg-BLG:hover {
    background-color: #2398a3;
}

.bg-LNG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/LNG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/LNG.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-LNG, .bg-LNG:hover {
    background-color: #2358a3;
}

.bg-JDG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/JDG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/JDG.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-JDG, .bg-JDG:hover {
    background-color: #2330a3;
}

.bg-NS {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/NS.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/NS.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-NS, .bg-NS:hover {
    background-color: #a32323;
}

.bg-BRO {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/BRO.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/BRO.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-BRO, .bg-BRO:hover {
    background-color: #23a34e;
}

.bg-KDF {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/KDF.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/KDF.png') no-repeat;
    background-position-x: 7%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-KDF, .bg-KDF:hover {
    background-color: #a33f23;
}

.bg-HLE {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/HLE.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/HLE.png') no-repeat;
    background-position-x: 1%;
    background-position-y: 35%;
    background-size: 30%;
}

.tooltip-team-participant.bg-HLE, .bg-HLE:hover {
    background-color: #a34e23;
}

.bg-DFM {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/DFM.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/DFM.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 40%;
    background-size: 20%;
}

.tooltip-team-participant.bg-DFM, .bg-DFM:hover {
    background-color: #2390a3;
}

.bg-GG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/GG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/GG.png') no-repeat;
    background-position-x: 7%;
    background-position-y: 35%;
    background-size: 20%;
}

.tooltip-team-participant.bg-GG, .bg-GG:hover {
    background-color: #a37823;
}

.bg-100T {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/100T.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/100T.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 40%;
    background-size: 20%;
}

.tooltip-team-participant.bg-100T, .bg-100T:hover {
    background-color: #080a24;
}

.bg-LSB {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/LSB.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/LSB.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 45%;
    background-size: 20%;
}

.tooltip-team-participant.bg-LSB, .bg-LSB:hover {
    background-color: #a37823;
}

.bg-EDG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/EDG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/EDG.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 48%;
    background-size: 22%;
}

.tooltip-team-participant.bg-EDG, .bg-EDG:hover {
    background-color: #2323a3;
}

.bg-WBG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/WBG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/WBG.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 33%;
    background-size: 22%;
}

.tooltip-team-participant.bg-WBG, .bg-WBG:hover {
    background-color: #0a0a0a;
}

.bg-NRG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/NRG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/NRG.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 22%;
}

.tooltip-team-participant.bg-NRG, .bg-NRG:hover {
    background-color: #0a0a0a;
}

.bg-TSM {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/TSM.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/TSM.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 22%;
}

.tooltip-team-participant.bg-TSM, .bg-TSM:hover {
    background-color: #0a0a0a;
}

.bg-TH {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/TH.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/TH.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 22%;
}

.tooltip-team-participant.bg-TH, .bg-TH:hover {
    background-color: #0a0a0a;
}

.bg-KT {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/KT.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/KT.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 30%;
    background-size: 22%;
}

.tooltip-team-participant.bg-KT, .bg-KT:hover {
    background-color: #0f0303;
}

.bg-DRX {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/DRX.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/DRX.png') no-repeat;
    background-position-x: 7%;
    background-position-y: 30%;
    background-size: 20%;
}

.tooltip-team-participant.bg-DRX, .bg-DRX:hover {
    background-color: #2323a3;
}

.bg-TW {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/TW.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/TW.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 35%;
    background-size: 20%;
}

.tooltip-team-participant.bg-TW, .bg-TW:hover {
    background-color: #2323a3;
}

.bg-TES {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/TES.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/TES.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 45%;
    background-size: 20%;
}

.tooltip-team-participant.bg-TES, .bg-TES:hover {
    background-color: #a32323;
}

.bg-FLY {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/FLY.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/FLY.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 45%;
    background-size: 20%;
}

.tooltip-team-participant.bg-FLY, .bg-FLY:hover {
    background-color: #23a369;
}

.bg-DIG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/DIG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/DIG.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 45%;
    background-size: 20%;
}

.tooltip-team-participant.bg-DIG, .bg-DIG:hover {
    background-color: #a39a23;
}

.bg-RNG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/RNG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/RNG.png') no-repeat;
    background-position-x: 3.9%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-RNG, .bg-RNG:hover {
    background-color: #a36923;
}

.bg-Z10 {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/Z10.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/Z10.png') no-repeat;
    background-position-x: 3.9%;
    background-position-y: 50%;
    background-size: 25%;
}


.tooltip-team-participant.bg-Z10, .bg-Z10:hover {
    background-color: #2369a3;
}

.bg-AST {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/AST.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/AST.png') no-repeat;
    background-position-x: 4%;
    background-position-y: 50%;
    background-size: 25%;
}


.tooltip-team-participant.bg-AST, .bg-AST:hover {
    background-color: #2349a3;
}

.bg-AL {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/AL.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/AL.png') no-repeat;
    background-position-x: 4%;
    background-position-y: 30%;
    background-size: 25%;
}

.tooltip-team-participant.bg-AL, .bg-AL:hover {
    background-color: #2523a3;
}

.bg-IG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/IG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/IG.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 40%;
    background-size: 20%;
}

.tooltip-team-participant.bg-IG, .bg-IG:hover {
    background-color: #2390a3;
}

.bg-EXL {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/EXL.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/EXL.png') no-repeat;
    background-position-x: 4%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-EXL, .bg-EXL:hover {
    background-color: #2523a3;
}

.bg-FPX {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/FPX.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/FPX.png') no-repeat;
    background-position-x: 2%;
    background-position-y: 50%;
    background-size: 30%;
}

.tooltip-team-participant.bg-FPX, .bg-FPX:hover {
    background-color: #6e1919;
}

.bg-SG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/SG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/SG.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-SG, .bg-SG:hover {
    background-color: #6e1919;
}

.bg-R7 {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/R7.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/R7.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-R7, .bg-R7:hover {
    background-color: #2390a3;
}

.bg-MAD {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/MAD.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/MAD.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-MAD, .bg-MAD:hover {
    background-color: #2523a3;
}

.bg-TT {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/TT.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/TT.png') no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-TT, .bg-TT:hover {
    background-color: #2523a3;
}

.bg-INF {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/INF.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/INF.png') no-repeat;
    background-position-x: 7%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-INF, .bg-INF:hover {
    background-color: #a33423;
}

.bg-FNC {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/FNC.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/FNC.png') no-repeat;
    background-position-x: 7%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-FNC, .bg-FNC:hover {
    background-color: #a33423;
}

.bg-SK {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/SK.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/SK.png') no-repeat;
    background-position-x: 11%;
    background-position-y: 50%;
    background-size: 13%;
}

.tooltip-team-participant.bg-SK, .bg-SK:hover {
    background-color: #0a0a0a;
}

.bg-KOI {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/KOI.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/KOI.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 80%;
    background-size: 20%;
}

.tooltip-team-participant.bg-KOI, .bg-KOI:hover {
    background-color: #4523a3;
}

.bg-BDS {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/BDS.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/BDS.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-BDS, .bg-BDS:hover {
    background-color: #a32385;
}

.bg-G2 {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/G2.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/G2.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-G2, .bg-G2:hover {
    background-color: #a32323;
}

.bg-VIT {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/VIT.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/VIT.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-VIT, .bg-VIT:hover {
    background-color: #a39a23;
}

.bg-WP {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/WP.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/WP.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 60%;
    background-size: 20%;
}

.tooltip-team-participant.bg-WP, .bg-WP:hover {
    background-color: #2523a3;
}

.bg-UP {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/UP.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/UP.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 35%;
    background-size: 25%;
}

.tooltip-team-participant.bg-UP, .bg-UP:hover {
    background-color: #2523a3;
}

.bg-EG {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/EG.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/EG.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-EG, .bg-EG:hover {
    background-color: #2523a3;
}

.bg-NSR {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/NSR.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/NSR.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 35%;
    background-size: 25%;
}

.tooltip-team-participant.bg-NSR, .bg-NSR:hover {
    background-color: #a33423;
}

.bg-WE {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/WE.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/WE.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 35%;
    background-size: 25%;
}

.tooltip-team-participant.bg-WE, .bg-WE:hover {
    background-color: #6e1919;
}

.bg-SHA {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/SHA.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/SHA.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 35%;
    background-size: 25%;
}

.tooltip-team-participant.bg-SHA, .bg-SHA:hover {
    background-color: #0a0a0a;
}

.bg-JDM {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/JDM.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/JDM.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-JDM, .bg-JDM:hover {
    background-color: #6e1919;
}

.bg-MJ {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/MJ.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/MJ.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-MJ, .bg-MJ:hover {
    background-color: #a33423;
}

.bg-GAM {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/GAM.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/GAM.png') no-repeat;
    background-position-x: 10%;
    background-position-y: 50%;
    background-size: 18%;
}

.tooltip-team-participant.bg-GAM, .bg-GAM:hover {
    background-color: #a37823;
}

.bg-GE {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/GE.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/GE.png') no-repeat;
    background-position-x: 6%;
    background-position-y: 50%;
    background-size: 15%;
}

.tooltip-team-participant.bg-GE, .bg-GE:hover {
    background-color: #a34e23;
}

.bg-APOSENTADO {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/APOSENTADO.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/APOSENTADO.png') no-repeat;
    background-position-x: 3%;
    background-position-y: 50%;
    background-size: 25%;
}

.tooltip-team-participant.bg-APOSENTADO, .bg-APOSENTADO:hover {
    background-color: #4e1212;
}

.bg-CBOLAO1 {
    background: linear-gradient(rgba(36, 38, 43, .65), rgba(36, 38, 43, .65)), url('https://victti-dev.com.br/public/images/teams/CBOLAO.png') no-repeat;
    background: linear-gradient(rgba(var(--teamR1), var(--teamG1), var(--teamB1), var(--teamOpacity)), rgba(var(--teamR2), var(--teamG2), var(--teamB2), var(--teamOpacity))), url('https://victti-dev.com.br/public/images/teams/CBOLAO.png') no-repeat;
    background-position-x: 8%;
    background-position-y: 50%;
    background-size: 20%;
}

.tooltip-team-participant.bg-CBOLAO1, .bg-CBOLAO1:hover {
    background-color: #4523a3;
}

.tooltip-team-blue-bg, .tooltip-team-red-bg {
    background-color: #0a0a0a;
}

.tooltip-team-blue-bg {
    background-position-x: 95%;
    --teamOpacity: .0;
}

.tooltip-team-red-bg {
    background-position-x: 5%;
    --teamOpacity: .0;
}