.navigation {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: rgb(2 2 2);
    border-bottom: 1px solid white;
    color: black;

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-family: Raleway,sans-serif;
    font-weight: 600;

    z-index: 1;
}

.brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.navigation-menu {
    margin-left: auto;
}

.navigation-menu ul {
    display: flex;
    margin-right: 2rem;
    padding: 0;
}

.navigation-menu li {
    list-style-type: none;
    margin: 0 0.5rem;
}

.navigation-menu li::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: .3s;
    left: 50%;
    position: relative;
}

.navigation-menu li:hover::after {
    left: 0;
    width: 100%;
}

.navigation-menu li a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 0.5rem;
}

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: #2642af;
}

@media screen and (max-width: 768px) {
    .is-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        transition-property: all; 
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 500ms;
    }

    .is-sticky.down {
        top: -6rem;
    }

    .hamburger {
      display: block;
    }

    .navigation-menu ul {
        position: absolute;
        top: 4.2rem;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 4.2rem);
        background-color: rgb(27, 30, 39);
        border-top: 1px solid black;
        display: none;
    }

    .navigation-menu li {
        text-align: center;
        margin: 0;
    }

    .navigation-menu li a {
        color: #fff;
        width: 100%;
        padding: 1.5rem 0;
    }

    .navigation-menu li:hover {
        background-color: rgb(24, 26, 34);
    }

    .navigation-menu.expanded ul {
        display: block;
    }
}