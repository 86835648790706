body:has(.lolpros-body) {
    background-color: #111111 !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    height: 100%;
}

body:has(.lolpros-body.bootcamp) {
    background-image: url("https://victti-dev.com.br/public/images/background/WORLDS.webp");
}

body:has(.lolpros-body.br) {
    background-image: url("https://victti-dev.com.br/public/images/background/CBLOL_Fundo_B-01.webp");
}

body:has(.lolpros-body.msi) {
    background-image: url("https://victti-dev.com.br/public/images/background/MSI.png");
}