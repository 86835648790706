
.blocked {
    cursor: not-allowed !important
}

.user-search {
    position: relative;
    margin-bottom: 30px;
}

.user-search .region-select {
    position: absolute;
    right: 100px;
    top: 29%;
    background: #141414;
    width: 60px;
    font-size: 13px;
    border: 1px solid #ddd;
    height: 35px;
    color: #fff;
    border: none;
    border-radius: 18px;
    text-align: center;
    cursor: pointer;
    transition: all .2s;
    
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.user-search .deep-select {
    position: absolute;
    right: 165px;
    top: 29%;
    background: #141414;
    width: 60px;
    font-size: 13px;
    border: 1px solid #ddd;
    height: 35px;
    color: #fff;
    border: none;
    border-radius: 18px;
    text-align: center;
    transition: all .2s;
    
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.user-search input[type=search]
{
    padding: 20px 180px 20px 20px;
    width: 600px;
    background: rgba(36,37,41,.77);
    border-radius: 28px;
    box-shadow: 0 4px 30px rgba(0,0,0,.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(36,37,41,.77);
    color: #ddd;
    outline-width: 0;
}

.user-search button {
    background-color: #00000000;
    border: 0px;
    position: absolute;
    cursor: pointer;
}

.user-search .update-button {
    top: 38%;
    right: 65px;
    width: 3.5%;
}

.user-search .search-button {
    top: 20%;
    right: 25px;
    width: 5%;
}

.user-search button svg {
    width: 100%;
}

.wrap-table {
    width: 760px;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 !important;
}

.table {
    width: 100%;
    display: table;
    margin: 0;
    padding: 0 !important;
}

.table, .row {
    width: 100%!important;
}

.row {
    display: table-row;
    background: #222;
}

.row.header {
    color: #fff;
    background: #333;
}

.cell {
    display: table-cell;
}

.row .cell {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    font-weight: inherit!important;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #02020255;
    text-align: center;
}

.row.header .cell {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: inherit!important;
    padding-top: 19px;
    padding-bottom: 19px;
}

.row .cell:nth-child(1) {
    width: 50%;
    padding-left: 40px;
}

.row .cell:nth-child(2) {
    width: 50%;
}

/* clears the ‘X’ from Internet Explorer */
input.user-search[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input.user-search[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input.user-search[type="search"]::-webkit-search-decoration,
input.user-search[type="search"]::-webkit-search-cancel-button,
input.user-search[type="search"]::-webkit-search-results-button,
input.user-search[type="search"]::-webkit-search-results-decoration { display: none; }