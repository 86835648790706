body {
    background-color: rgb(24 26 32);
}

iframe {
    width: 100%;
    height: 100%;
}

.div-content {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: calc(100vh - 5.3rem);
}

.div-content div {
    padding: 15px 5px;
}

.div-top {
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 15px;
    text-align: center;
}

.div-bottom {
    align-items: center;
    display: flex;
    justify-content: center;
}

.div-bottom a {
    font-size: 12px;
    margin: 0 10px;
}

.div-bottom a i {
    padding-left: 8px;
    padding-right: 8px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-4x {
    font-size: 4em;
}

.fa-twitter-square:before {
    content: "\f081";
}

.fa-github-square:before {
    content: "\f092";
}

.fa-youtube-square:before {
    content: "\f166";
}

@media only screen and (max-width: 600px) {
    .a-gmail {
      display: none;
    }
}